exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-classes-tsx": () => import("./../../../src/pages/classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-client-information-form-thanks-tsx": () => import("./../../../src/pages/client-information-form/thanks.tsx" /* webpackChunkName: "component---src-pages-client-information-form-thanks-tsx" */),
  "component---src-pages-client-information-form-tsx": () => import("./../../../src/pages/client-information-form.tsx" /* webpackChunkName: "component---src-pages-client-information-form-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../../../src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-flavours-tsx": () => import("./../../../src/pages/flavours.tsx" /* webpackChunkName: "component---src-pages-flavours-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prices-tsx": () => import("./../../../src/pages/prices.tsx" /* webpackChunkName: "component---src-pages-prices-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rentals-tsx": () => import("./../../../src/pages/rentals.tsx" /* webpackChunkName: "component---src-pages-rentals-tsx" */),
  "component---src-pages-sample-box-tsx": () => import("./../../../src/pages/sample-box.tsx" /* webpackChunkName: "component---src-pages-sample-box-tsx" */)
}

